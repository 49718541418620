import React from "react";
import Modal from "react-modal";
import '../../../styles/modale.css'; // Убедитесь, что путь к CSS файлу верный

const ExitModal = ({ modalIsOpen, setModalIsOpen, handleLogout }) => {
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={() => setModalIsOpen(false)}
            contentLabel="Подтверждение выхода"
            className="modalContent" // CSS класс для стилизации содержимого модального окна
            overlayClassName="modalOverlay" // CSS класс для стилизации оверлея модального окна
        >
            <h2 className="modalHeader">Аккаунтдан чиқиш</h2>
            <p className="modalText">Ҳақиқатан ҳам ҳозирги профилингиздан чиқмоқчимисиз?</p>
            <div className="modalButtonContainer">
                <button className="modalButton yesButton" onClick={handleLogout}>Ҳа</button>
                <button className="modalButton noButton" onClick={() => setModalIsOpen(false)}>Йўқ</button>
            </div>
        </Modal>
    );
};

export default ExitModal;
