import React from "react";
import '../styles/contact.css'
import QRcodeTelegram from "../assets/images/qr_code.png";
import ComingSoon from "../assets/images/comming_soon.png"

const Contact = () => {
  return (
      <div className="container_contact">
          <div className='contactHeader'>Контакты</div>
          <div className="contactCards">
              <div className='contactCard'>
                  <div className='contactItem'>
                      <img src={ComingSoon} alt="QR Code" className="qrCode"/>
                  </div>
                  <div className="contactItem">Наш телеграм бот для заказа</div>
                  <a href="" className="contactLink">Скоро...</a>

              </div>
              <div className='contactCard'>
                  <div className='contactItem'>
                      {/* Здесь будет ваш QR-код, возможно, как img с src ссылкой на ваш QR-код */}
                      <img src={ComingSoon} alt="QR Code" className="qrCode"/>
                  </div>
                  <div className="contactItem">Мобильное приложение для заказа</div>
                  <a href="" className="contactLink">Скоро...</a>

              </div>
              <div className='contactCard'>
                  <div className='contactItem'>
                      {/* Здесь будет ваш QR-код, возможно, как img с src ссылкой на ваш QR-код */}
                      <img src={QRcodeTelegram} alt="QR Code" className="qrCode"/>
                  </div>
                  <div className="contactItem">Контактный номер для связи</div>
                  <a href="tel:79175140888" className="contactLink">+7 917 514 08 48</a>

              </div>

          </div>

      </div>
  );
};

export default Contact;
