import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import logo from "../../assets/images/res-logo.png";

import "../../styles/footer.css";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
      <footer className="footer">
        <Container>
          <Row>
            <Col lg="3" md="4" sm="6">
              <div className="footer__logo text-start">
                <img src={logo} alt="logo" />
                <h5>Вкусное удовольствие</h5>
                <p>
                  Вкусное удовольствие мы предлагаем не просто еду, а исключительные кулинарные впечатления, созданные с любовью и мастерством.
                </p>
              </div>
            </Col>

            <Col lg="3" md="4" sm="6">
              <h5 className="footer__title">Время Доставки</h5>
              <ListGroup className="deliver__time-list">
                <ListGroupItem className="delivery__time-item border-0 ps-0">
                  <span>Воскресенье - Четверг</span>
                  <p>10:00 - 23:00</p>
                </ListGroupItem>

                <ListGroupItem className="delivery__time-item border-0 ps-0">
                  <span>Пятница - Суббота</span>
                  <p>Выходной</p>
                </ListGroupItem>
              </ListGroup>
            </Col>

            <Col lg="3" md="4" sm="6">
              <h5 className="footer__title">Контакт</h5>
              <ListGroup className="deliver__time-list">
                <ListGroupItem className="delivery__time-item border-0 ps-0">
                  <p>Адрес: 119049 г. Москва, ул. Донская, д. 8 стр. 1</p>
                </ListGroupItem>
                <ListGroupItem className="delivery__time-item border-0 ps-0">
                  <span>Телефон: +7 917 514-08-48</span>
                </ListGroupItem>

                <ListGroupItem className="delivery__time-item border-0 ps-0">
                  <span>Email: ahadovkarim@gmail.com</span>
                </ListGroupItem>
              </ListGroup>
            </Col>

            <Col lg="3" md="4" sm="6">
              <h5 className="footer__title">Новостная Рассылка</h5>
              <p>Подпишитесь на нашу новостную рассылку</p>
              <div className="newsletter">
                <input type="email" placeholder="Введите ваш email" />
                <span>
            <i class="ri-send-plane-line"></i>
          </span>
              </div>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col lg="6" md="6">
              <p className="copyright__text">
                Авторское право - 2024, сайт создан . Все права защищены.
              </p>
            </Col>

          </Row>
        </Container>
      </footer>
  );
};

export default Footer;
