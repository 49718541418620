import React from "react";
import Slider from "react-slick";

import ava01 from "../../../assets/images/ava-1.jpg";
import ava02 from "../../../assets/images/ava-2.jpg";
import ava03 from "../../../assets/images/ava-3.jpg";

import "../../../styles/slider.css";

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
      <Slider {...settings}>
        <div>
          <p className="review__text">
            "В этом ресторане самые вкусные бургеры в городе! Обслуживание на высоте, а атмосфера уютная. Всем рекомендую посетить и насладиться кулинарией."
          </p>
          <div className="slider__content d-flex align-items-center gap-3">
            <img src={ava01} alt="avatar" className="rounded" />
            <h6>Алексей Миронов</h6>
          </div>
        </div>
        <div>
          <p className="review__text">
            "Отличное место для семейного ужина. Пицца просто невероятная, и даже дети остались довольны. Благодарю за прекрасный вечер!"
          </p>
          <div className="slider__content d-flex align-items-center gap-3">
            <img src={ava02} alt="avatar" className="rounded" />
            <h6>Марина Васильева</h6>
          </div>
        </div>
        <div>
          <p className="review__text">
            "Я фанат итальянской кухни, и ваш ресторан не перестает удивлять разнообразием блюд. Все свежее и очень вкусное. Спасибо за вашу работу!"
          </p>
          <div className="slider__content d-flex align-items-center gap-3">
            <img src={ava03} alt="avatar" className="rounded" />
            <h6>Сергей Авдеев</h6>
          </div>
        </div>
      </Slider>
  );

};

export default TestimonialSlider;
