// all images imported from images directory
import product_01_image_01 from "../images/product_01.jpg";
import product_01_image_02 from "../images/product_01.1.jpg";
import product_01_image_03 from "../images/product_01.3.jpg";

import product_02_image_01 from "../images/product_2.1.jpg";
import product_02_image_02 from "../images/product_2.2.jpg";
import product_02_image_03 from "../images/product_2.3.jpg";

import product_03_image_01 from "../images/product_3.1.jpg";
import product_03_image_02 from "../images/product_3.2.jpg";
import product_03_image_03 from "../images/product_3.3.jpg";

import product_04_image_01 from "../images/product_4.1.jpg";
import product_04_image_02 from "../images/product_4.2.jpg";
import product_04_image_03 from "../images/product_4.3.png";

import product_05_image_01 from "../images/product_04.jpg";
import product_05_image_02 from "../images/product_08.jpg";
import product_05_image_03 from "../images/product_09.jpg";

import product_06_image_01 from "../images/bread(1).png";
import product_06_image_02 from "../images/bread(2).png";
import product_06_image_03 from "../images/bread(3).png";

const products = [
  {
    id: "01",
    title: "Чикен Бургер",
    price: 180.0,
    image01: product_01_image_01,
    image02: product_01_image_02,
    image03: product_01_image_03,
    category: "Burger",
    desc: "Сочный бургер с курицей, свежими овощами и специальным соусом на пшеничной булочке.",
  },
  {
    id: "02",
    title: "Вегетарианская Пицца",
    price: 870.0,
    image01: product_02_image_01,
    image02: product_02_image_02,
    image03: product_02_image_03,
    category: "Pizza",
    desc: "Пицца с миксом свежих овощей, моцареллой и томатным соусом, идеальный выбор для вегетарианцев.",
  },
  {
    id: "03",
    title: "Двойная Пицца с Четырьмя Сырами",
    price: 830.0,
    image01: product_03_image_01,
    image02: product_03_image_02,
    image03: product_03_image_03,
    category: "Pizza",
    desc: "Классическая пицца с четырьмя видами сыра на тонком тесте.",
  },
  {
    id: "04",
    title: "Мексиканская Волна",
    price: 830.0,
    image01: product_04_image_01,
    image02: product_04_image_02,
    image03: product_04_image_03,
    category: "Pizza",
    desc: "Острая пицца с перцами халапеньо, кукурузой, оливками и сочным мясом.",
  },
  {
    id: "05",
    title: "Чизбургер",
    price: 100.0,
    image01: product_05_image_01,
    image02: product_05_image_02,
    image03: product_05_image_03,
    category: "Burger",
    desc: "Классический бургер с двойным сыром, сочной котлетой и секретным соусом.",
  },
  {
    id: "06",
    title: "Королевский Чизбургер",
    price: 100.0,
    image01: product_01_image_01,
    image02: product_01_image_02,
    image03: product_01_image_03,
    category: "Burger",
    desc: "Бургер для настоящих гурманов с тройным сыром и премиум беконом.",
  },
  {
    id: "07",
    title: "Морская Пицца",
    price: 870.0,
    image01: product_02_image_02,
    image02: product_02_image_01,
    image03: product_02_image_03,
    category: "Pizza",
    desc: "Пицца с морскими деликатесами, свежим укропом и кремовым соусом.",
  },
  {
    id: "08",
    title: "Пицца с Тонким Тестом и Сыром",
    price: 830.0,
    image01: product_03_image_02,
    image02: product_03_image_01,
    image03: product_03_image_03,
    category: "Pizza",
    desc: "Пицца на тонком тесте с тройной порцией моцареллы и ароматными травами.",
  },
  {
    id: "09",
    title: "Пицца с Грибами",
    price: 830.0,
    image01: product_04_image_02,
    image02: product_04_image_01,
    image03: product_04_image_03,
    category: "Pizza",
    desc: "Пицца с порцией шампиньонов, сладким луком и сливочным соусом.",
  },
  {
    id: "10",
    title: "Классический Гамбургер",
    price: 100.0,
    image01: product_05_image_02,
    image02: product_05_image_01,
    image03: product_05_image_03,
    category: "Burger",
    desc: "Настоящий американский гамбургер с котлетой из говядины, салатом и помидорами.",
  },
  {
    id: "11",
    title: "Хрустящий Хлеб",
    price: 260.0,
    image01: product_06_image_01,
    image02: product_06_image_02,
    image03: product_06_image_03,
    category: "Bread",
    desc: "Хрустящий свежеиспеченный хлеб, идеален для завтрака или как добавка к основному блюду.",
  },
  {
    id: "12",
    title: "Вкусный Хлеб",
    price: 260.0,
    image01: product_06_image_02,
    image02: product_06_image_01,
    image03: product_06_image_03,
    category: "Bread",
    desc: "Мягкий хлеб с нежной текстурой и богатым вкусом, идеален для сэндвичей.",
  },
  {
    id: "13",
    title: "Буханка Хлеба",
    price: 260.0,
    image01: product_06_image_03,
    image02: product_06_image_02,
    image03: product_06_image_03,
    category: "Bread",
    desc: "Традиционная буханка хлеба, выпеченная по старинным рецептам."
  },
];

export default products;
