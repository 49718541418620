import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/UI/common-section/CommonSection';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import '../styles/profile.css'
import ExitModal from "../components/UI/modal/ExitModal";


Modal.setAppElement('#root');
const Profile = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const navigate = useNavigate();

    const handleLogout = () => {
        // Очистка localStorage или других действий при выходе
        localStorage.removeItem('phoneNumber');
        navigate('/login'); // Перенаправление на страницу входа
    };


    useEffect(() => {
        const phoneNumber = localStorage.getItem('phoneNumber');
        console.log(phoneNumber)
        if (phoneNumber) {
            axios.get(`http://localhost:4000/api/user-profile`, { params: { phone: phoneNumber } })
                .then(response => {
                    setUserData(response.data[0]); // Предполагаем, что API возвращает массив пользователей
                })
                .catch(error => {
                    console.error("Ошибка при получении данных профиля:", error);
                });


        }
    }, []);

    return (
        <Helmet title="Профиль">
            <section className="profileContainer">
            <div className="profile-container">
                {userData ? (
                    <div>
                        <div className="profile-header">Профиль</div>
                        <div className="user-card">
                            <p>{userData.name}</p>
                            <p>+{userData.phone}</p>
                        </div>
                        <div className="section">
                            <p>Личные данные</p>
                            <span>></span>
                        </div>
                        <div className="section">
                            <p>Мои адреса</p>
                            <span>></span>
                        </div>
                        <button className="logout-button" onClick={() => setModalIsOpen(true)}>Выйти из аккаунта
                        </button>
                    </div>
                ) : (
                    <p>Загрузка данных профиля...</p>
                )}
            </div>
            </section>

            <ExitModal
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                handleLogout={handleLogout}
            />
        </Helmet>
    );
};

export default Profile;
