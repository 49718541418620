import React, {useState, useEffect} from "react";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/common-section/CommonSection";
import {Container, Row, Col} from "reactstrap";
import {Link} from "react-router-dom";
import '../styles/login.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [smsSent, setSmsSent] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [userEnteredCode, setUserEnteredCode] = useState("");
    const [isCodeValid, setIsCodeValid] = useState(false);
    const [isProfileActive, setIsProfileActive] = useState(false);

    const navigate = useNavigate();


    useEffect(() => {
        const phoneNumber = localStorage.getItem('phoneNumber');
        if (phoneNumber) {
            navigate('/profile');
        }
    }, [navigate]);


    const generateCode = () => {
        const code = Math.floor(1000 + Math.random() * 9000);
        setVerificationCode(code.toString());
        return code;
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        const formattedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
        const code = generateCode(); // Генерируем код

        try {
            // Продолжение отправки запроса на получение токена и отправку SMS, используя сгенерированный код
            const tokenResponse = await axios.post('http://localhost:3000/api/get-token', {
                email: "ahadovkarim@gmail.com",
                password: "iT13lj1JI7qxJsPUdqnNDeVQ3XKxk9BPkYp71CFJ"
            });

            const token = tokenResponse.data.data.token;

            console.log(`Token: ${token}`);

            // Отправляем SMS с кодом
            await axios.post('http://localhost:3000/api/send-sms', {
                mobile_phone: formattedPhoneNumber,
                message: `Ваш код от AkmirTeam DEMO SITE - ${code}. Никому его не сообщайте!`,
                from: "4546",
                callback_url: "http://0000.uz/test.php",
                token: token
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            setSmsSent(true);
        } catch (error) {
            console.error('Ошибка при отправке SMS:', error);
        }
    };

    const handlePhoneChange = (e) => {
        let value = e.target.value.replace(/[^\d]/g, '');

        if (!value.startsWith('7')) {
            value = '7' + value;
        }

        value = "+" + value

        if (value.length > 4) {
            value = value.slice(0, 4) + ' (' + value.slice(4);
        }
        if (value.length > 8) {
            value = value.slice(0, 8) + ') ' + value.slice(8);
        }
        if (value.length > 13) {
            value = value.slice(0, 13) + '-' + value.slice(13);
        }
        if (value.length > 16) {
            value = value.slice(0, 16) + '-' + value.slice(16);
        }
        if (value.length > 19) {
            value = value.slice(0, 19) + '-' + value.slice(19);
        }

        if (value.length > 20) {
            value = value.slice(0, 20);
        }

        setPhoneNumber(value);
        setIsPhoneValid(value.length === 19);

    };

    const handleCodeChange = (e) => {
        setUserEnteredCode(e.target.value);
    };


    const verifyCode = () => {
        const isValid = userEnteredCode === verificationCode;
        setIsCodeValid(isValid);
        if (isValid) {
            const formattedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');
            localStorage.setItem('phoneNumber', formattedPhoneNumber); // Сохранение номера телефона
            setIsProfileActive(true); // Симуляция активации профиля после успешного ввода кода
            navigate('/profile'); // Переадресация на страницу профиля
        }
    };

    const renderVerificationInput = () => {
        if (!smsSent) return null;

        return (
            <>
                <input
                    type="text"
                    placeholder="Введите код"
                    value={userEnteredCode}
                    onChange={(e) => setUserEnteredCode(e.target.value)}
                    maxLength="4"
                />
                <button
                    type="button"
                    onClick={verifyCode}
                    disabled={userEnteredCode.length < 4} // Кнопка активна только если введены 4 символа
                >
                    Подтвердить
                </button>
                {isCodeValid ? <p>Код верный!</p> : userEnteredCode.length === 4 ? <p>Неверный код, попробуйте снова.</p> : null}
            </>
        );
    };

    const renderProfileScreen = () => {
        if (!isProfileActive) return null;

        return (
            <div>
                <h2>Профиль активирован</h2>
                <p>Добро пожаловать в ваш новый аккаунт!</p>
            </div>
        );
    };

    return (
        <Helmet title="Login">
            <CommonSection title="Регистрация" />
            <section>
                <Container>
                    <Row>
                        <Col lg="6" md="6" sm="12" className="m-auto text-center">
                            <div className="login-container">
                                <h2 className="login-title">Введите свой номер</h2>
                                {!isProfileActive ? (
                                    <form onSubmit={submitHandler} className="login-form">
                                        <div className="phone-input-container">
                                            <input
                                                type="tel"
                                                className="phone-input"
                                                placeholder="+7 (__) ___-__-__"
                                                value={phoneNumber}
                                                onChange={handlePhoneChange}
                                            />
                                        </div>
                                        {!smsSent && (
                                            <button type="submit" className="submit-button" disabled={!isPhoneValid}>
                                                Получить код доступа
                                            </button>
                                        )}
                                        {renderVerificationInput()}
                                    </form>
                                ) : renderProfileScreen()}
                                <Link to="/register" className="register-link">
                                    Нету еще аккаунта? Создайте ваш аккаунт
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Helmet>
    );
};

export default Login;