import React, { useState, useEffect } from "react";

import Helmet from "../components/Helmet/Helmet.js";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";

import heroImg from "../assets/images/curyer3.png";
import "../styles/hero-section.css";

import { Link } from "react-router-dom";

import Category from "../components/UI/category/Category.jsx";

import "../styles/home.css";

import featureImg01 from "../assets/images/service-01.png";
import featureImg02 from "../assets/images/service-02.png";
import featureImg03 from "../assets/images/service-03.png";

import products from "../assets/fake-data/products.js";

import foodCategoryImg01 from "../assets/images/hamburger.png";
import foodCategoryImg02 from "../assets/images/pizza.png";
import foodCategoryImg03 from "../assets/images/bread.png";

import ProductCard from "../components/UI/product-card/ProductCard.jsx";

import whyImg from "../assets/images/curyer.png";

import networkImg from "../assets/images/curyer6.png";

import TestimonialSlider from "../components/UI/slider/TestimonialSlider.jsx";

const featureData = [
  {
    title: "Быстрая доставка",
    imgUrl: featureImg01,
    desc: "Гарантируем сверхбыструю доставку вашего заказа прямо к двери вашего дома, максимально сохраняя качество и свежесть блюд.",
  },

  {
    title: "Ужин в ресторане",
    imgUrl: featureImg02,
    desc: "Предлагаем уникальный опыт ужина в нашем ресторане с изысканным меню и превосходным сервисом для идеального вечера.",
  },
  {
    title: "Легкий самовывоз",
    imgUrl: featureImg03,
    desc: "Удобный и быстрый самовывоз для тех, кто ценит своё время. Закажите заранее и заберите ваш заказ без ожидания.",
  },
];


const Home = () => {
  const [category, setCategory] = useState("ALL");
  const [allProducts, setAllProducts] = useState(products);

  const [hotPizza, setHotPizza] = useState([]);

  useEffect(() => {
    const filteredPizza = products.filter((item) => item.category === "Pizza");
    const slicePizza = filteredPizza.slice(0, 4);
    setHotPizza(slicePizza);
  }, []);

  useEffect(() => {
    if (category === "ALL") {
      setAllProducts(products);
    }

    if (category === "BURGER") {
      const filteredProducts = products.filter(
        (item) => item.category === "Burger"
      );

      setAllProducts(filteredProducts);
    }

    if (category === "PIZZA") {
      const filteredProducts = products.filter(
        (item) => item.category === "Pizza"
      );

      setAllProducts(filteredProducts);
    }

    if (category === "BREAD") {
      const filteredProducts = products.filter(
        (item) => item.category === "Bread"
      );

      setAllProducts(filteredProducts);
    }
  }, [category]);

  return (
    <Helmet title="Home">
      <section>
        <Container>
          <Row>
            <Col lg="6" md="6">
              <div className="hero__content  ">
                <h5 className="mb-3">Быстрая доставка еды</h5>
                <h1 className="mb-4 hero__title">
                  <span>ГОЛОДНЫ?</span> <br/> Просто закажите <br/> еду прямо
                  <span>к вашей двери</span>
                </h1>
                <p>
                  Познакомьтесь с нашей историей: от маленькой идеи до лидера отрасли. Узнайте, что вдохновляет нас каждый день, и как мы стремимся вносить изменения в мир вокруг.
                </p>
                <div className="hero__btns d-flex align-items-center gap-5 mt-4">
                  <button className="order__btn d-flex align-items-center justify-content-between">
                    Заказать сейчас <i class="ri-arrow-right-s-line"></i>
                  </button>

                  <button className="all__foods-btn">
                    <Link to="/foods">Посмотреть все продукты</Link>
                  </button>
                </div>

                <div className=" hero__service  d-flex align-items-center gap-5 mt-5 ">
                  <p className=" d-flex align-items-center gap-2 ">
                    <span className="shipping__icon">
                      <i class="ri-car-line"></i>
                    </span>{" "}
                    Быстрая доставка
                  </p>

                  <p className=" d-flex align-items-center gap-2 ">
                    <span className="shipping__icon">
                      <i class="ri-shield-check-line"></i>
                    </span>{" "}
                    100% халяль
                  </p>
                </div>
              </div>
            </Col>

            <Col lg="6" md="6">
              <div className="hero__img">
                <img src={heroImg} alt="hero-img" className="w-100" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-0">
        <Category />
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h5 className="feature__subtitle mb-4">Что мы предлагаем</h5>
              <h2 className="feature__title">Просто расслабьтесь дома</h2>
              <h2 className="feature__title">
                мы <span>позаботимся</span>
              </h2>
              <p className="mb-1 mt-4 feature__text">
                Наслаждайтесь высококачественным сервисом прямо у себя дома. Мы доставим лучшие блюда от избранных ресторанов.
              </p>
              <p className="feature__text">
                Мы предлагаем широкий ассортимент кулинарных изысков для любого случая. От классики до экзотических блюд - всё для вашего удовольствия.
              </p>
            </Col>


            {featureData.map((item, index) => (
              <Col lg="4" md="6" sm="6" key={index} className="mt-5">
                <div className="feature__item text-center px-5 py-3">
                  <img
                    src={item.imgUrl}
                    alt="feature-img"
                    className="w-25 mb-3"
                  />
                  <h5 className=" fw-bold mb-3">{item.title}</h5>
                  <p>{item.desc}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h2>Популярные блюда</h2>
            </Col>

            <Col lg="12">
              <div className="food__category d-flex align-items-center justify-content-center gap-4">
                <button
                  className={`all__btn  ${
                    category === "ALL" ? "foodBtnActive" : ""
                  } `}
                  onClick={() => setCategory("ALL")}
                >
                  All
                </button>
                <button
                  className={`d-flex align-items-center gap-2 ${
                    category === "BURGER" ? "foodBtnActive" : ""
                  } `}
                  onClick={() => setCategory("BURGER")}
                >
                  <img src={foodCategoryImg01} alt="" />
                  Бургеры
                </button>

                <button
                  className={`d-flex align-items-center gap-2 ${
                    category === "PIZZA" ? "foodBtnActive" : ""
                  } `}
                  onClick={() => setCategory("PIZZA")}
                >
                  <img src={foodCategoryImg02} alt="" />
                  Пицца
                </button>

                <button
                  className={`d-flex align-items-center gap-2 ${
                    category === "BREAD" ? "foodBtnActive" : ""
                  } `}
                  onClick={() => setCategory("BREAD")}
                >
                  <img src={foodCategoryImg03} alt="" />
                  Хлеб
                </button>
              </div>
            </Col>

            {allProducts.map((item) => (
              <Col lg="3" md="4" sm="6" xs="6" key={item.id} className="mt-5">
                <ProductCard item={item} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section className="why__choose-us">
        <Container>
          <Row>
            <Col lg="6" md="6">
              <img src={whyImg} alt="why-tasty-treat" className="w-100" />
            </Col>
            <Col lg="6" md="6">
              <div className="why__tasty-treat">
                <h2 className="tasty__treat-title mb-4">
                  Почему <span>Bon?</span>
                </h2>
                <p className="tasty__treat-desc">
                  Наша миссия – предложить вам не только вкусную еду, но и незабываемый опыт. Мы гордимся использованием только свежих ингредиентов, а также нашим вниманием к деталям и качеству обслуживания.
                </p>

                <ListGroup className="mt-4">
                  <ListGroupItem className="border-0 ps-0">
                    <p className=" choose__us-title d-flex align-items-center gap-2 ">
                      <i class="ri-checkbox-circle-line"></i> Свежие и вкусные блюда
                    </p>
                    <p className="choose__us-desc">
                      Мы тщательно выбираем ингредиенты для каждого блюда, чтобы обеспечить вам исключительный вкус и качество.
                    </p>
                  </ListGroupItem>

                  <ListGroupItem className="border-0 ps-0">
                    <p className="choose__us-title d-flex align-items-center gap-2 ">
                      <i class="ri-checkbox-circle-line"></i> Качественная поддержка
                    </p>
                    <p className="choose__us-desc">
                      Наша команда всегда готова помочь вам с выбором блюд и ответить на любые вопросы касательно вашего заказа.
                    </p>
                  </ListGroupItem>

                  <ListGroupItem className="border-0 ps-0">
                    <p className="choose__us-title d-flex align-items-center gap-2 ">
                      <i class="ri-checkbox-circle-line"></i>Заказ из любой точки
                    </p>
                    <p className="choose__us-desc">
                      Где бы вы ни находились, легко сделайте заказ через наш сайт или мобильное приложение для быстрой доставки.
                    </p>

                  </ListGroupItem>
                </ListGroup>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-0">
        <Container>
          <Row>
            <Col lg="12" className="text-center mb-5 ">
              <h2>Горячяя пицца</h2>
            </Col>

            {hotPizza.map((item) => (
              <Col lg="3" md="4" sm="6" xs="6" key={item.id}>
                <ProductCard item={item} />
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="6" md="6">
              <div className="testimonial ">
                <h5 className="testimonial__subtitle mb-4">Отзывы</h5>
                <h2 className="testimonial__title mb-4">
                  Что говорят наши <span>клиенты</span>
                </h2>
                <p className="testimonial__desc">
                  Наши клиенты ценят нас за внимание к деталям, качество обслуживания и уникальный вкус наших блюд. Мы гордимся тем, что можем предложить не просто еду, а истинное удовольствие от каждого приема пищи.
                </p>

                <TestimonialSlider />
              </div>

            </Col>

            <Col lg="6" md="6">
              <img src={networkImg} alt="testimonial-img" className="w-100" />
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Home;
